<template>
  <div>
    <title-nav :title="'댓글관리'" :nav="'CS / 댓글관리'" />
    <b-container fluid>
      <div class="card">
        <div class="card-header">
          <b-row>
            <b-col></b-col>
            <b-col cols="5">
              <b-input-group size="sm">
                <b-form-select
                  slot="prepend"
                  type="search"
                  v-model="searchType"
                  :options="searchOptions"
                  size="sm"
                  class="mr-3"
                ></b-form-select>
                <b-form-input
                  v-model="searchText"
                  placeholder="Search"
                  @keyup.enter="init"
                ></b-form-input>
                <b-input-group-append is-text size="sm">
                  <b-icon icon="search" @click="init"></b-icon>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </div>
        <div class="card-body p-0">
          <b-table
            striped
            hover
            bordered
            class="table-sm text-sm-center"
            :borderless="borderless"
            :dark="dark"
            :fixed="fixed"
            :no-border-collapse="noCollapse"
            :items="items"
            :fields="fields"
            :head-variant="headVariant"
            :busy="isBusy"
            show-empty
          >
            <template v-slot:cell(user_sn)="data">
              <router-link
                :to="{ name: 'detail', params: { usersn: data.value } }"
                variant="link"
                >{{ data.value }}</router-link
              >
            </template>

            <template v-slot:cell(del_sn)="data">
              <template v-if="data.value > 1000000"
                ><router-link
                  :to="{ name: 'detail', params: { usersn: data.value } }"
                  variant="link"
                  >{{ data.value }}</router-link
                ></template
              >
              <template v-else>{{ data.value }}</template>
            </template>

            <template v-slot:cell(reg_dt)="data">
              <span>{{ data.value | dateFilter }}</span>
            </template>

            <template v-slot:cell(acc)="data">
              <template v-if="data.value > 0"
                ><b-button
                  variant="link"
                  style="padding:0"
                  @click="
                    {
                      co_sn = data.item.co_sn
                      $refs.modal_view.show()
                    }
                  "
                  >{{ data.value }}</b-button
                ></template
              >
            </template>

            <template v-slot:cell(is_del)="data">
              <template v-if="data.value == 'N'"
                ><b-button
                  variant="outline-primary"
                  size="sm"
                  style="padding: 0 .2rem"
                  @click="delComment(data.item.co_sn)"
                  >삭제</b-button
                ></template
              >
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template v-slot:empty>
              <h4>데이터가 없습니다.</h4>
            </template>
          </b-table>
        </div>
        <div class="card-footer clearfix">
          <b-pagination
            v-model="curPage"
            :total-rows="records"
            :per-page="rowPerPage"
            @change="setPage"
            align="right"
          ></b-pagination>
        </div>
      </div>
    </b-container>
    <b-modal title="신고 내역" ref="modal_view" :hide-footer="true" @shown="initAccuse">
      <b-table
        striped
        hover
        small
        bordered
        :items="accuse_items"
        :fields="['user_sn', 'nick', 'description', 'upd_dt']"
      ></b-table>
    </b-modal>
  </div>
</template>

<script>
import { comment_list, comment_accuse_list, comment_delete } from '@/api/cs'

export default {
  name: 'Review',
  data() {
    return {
      curPage: 1,
      rowPerPage: 20,
      records: 0,
      isBusy: false,

      fields: [
        {
          key: 'co_sn',
          label: 'NO.'
        },
        {
          key: 'user_sn',
          label: '고객 번호'
        },
        {
          key: 'nick',
          label: '닉네임'
        },
        {
          key: 'lc_no',
          label: '강좌번호'
        },
        {
          key: 'comment',
          label: '댓글/답글 내용',
          tdClass: 'text-left'
        },
        {
          key: 'g_no',
          label: '원 댓글 NO.'
        },
        {
          key: 'reg_dt',
          label: '등록일'
        },
        {
          key: 'acc',
          label: '신고'
        },
        {
          key: 'is_del',
          label: '삭제'
        },
        {
          key: 'del_sn',
          label: '삭제자'
        }
      ],
      items: [],
      striped: true,
      bordered: true,
      borderless: false,
      small: true,
      hover: true,
      dark: false,
      fixed: false,
      tableVariant: '',
      noCollapse: false,
      headVariant: 'dark',
      searchType: '',
      searchText: '',
      searchOptions: [
        { value: '', text: '선택' },
        { value: 'nick', text: '닉네임' },
        { value: 'comment', text: '댓글내용' }
      ],
      accuse_items: [],
      co_sn: null
    }
  },
  watch: {
    rowPerPage: function(newValue) {
      console.log(newValue)
      this.init()
    }
  },
  methods: {
    async init() {
      this.isBusy = true
      const resp = await comment_list(
        this.curPage,
        this.rowPerPage,
        this.searchType,
        this.searchText
      )
      if (resp.code === '0000') {
        this.items = resp.data.list
      }
      this.records = resp.data.totCnt
      this.isBusy = false
    },
    async delComment(co_sn) {
      if (
        confirm(
          '댓글을 화면상에서 완전히 삭제하시겠습니까?\n한번 삭제한 댓글은 되돌릴 수 없습니다.'
        )
      ) {
        const resp = await comment_delete(co_sn)
        if (resp.code === '0000') {
          this.init()
        }
      }
    },
    async initAccuse() {
      const resp = await comment_accuse_list(this.co_sn)
      if (resp.code === '0000') {
        this.accuse_items = resp.data
      }
    },
    setPage(page) {
      this.curPage = page
      this.init()
    }
  },
  mounted() {
    this.init()
  }
}
</script>
